import React from "react"
import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Technologies.module.scss"

const Technologies = props => {
  const { title, subTitle, cards } = props
  return (
    <section className={styles.technologies}>
      <Container>
        <div className="text-center mb-5">
          <h2
            className="h1 main-heading-36"
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <p>{subTitle}</p>
        </div>
        <Row lg={6} className="justify-content-center">
          {cards?.map(({ title, image1 }, i) => (
            <Col lg={2} md={4} xs={6} className="text-center p-2" key={i}>
              <div className={styles.technologyIcon}>
                <img
                  src={image1 && image1[0]?.localFile?.publicURL}
                  alt={title}
                  decoding="async"
                  loading="lazy"
                />
              </div>
              <h3 className="h6 font-weight-normal pt-3 pb-3">{title}</h3>
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Technologies
